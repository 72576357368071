import { Show, splitProps } from "solid-js";
import { TitleProps } from "./model";

export function Title(props: TitleProps) {
  // const [local, others] = splitProps(props, ["class", "elements", ""]);

  return (
    <Show
      when={props.options?.editable}
      fallback={<h1 class={`font-700  leading-43px ${props.class ?? ""}`}>{props.elements?.title}</h1>}
    >
      <textarea
        class={`w-full text#n-200 border-dashed border-2 border#n-200 font-700  overflow-hidden placeholder:text#n-200  p-10px ${
          props.class ?? ""
        }`}
        value={props.elements.title}
        onInput={props.events?.onChange}
      />
    </Show>
  );
}
